import React from "react";

interface Props {

}

export const DocStackAttack: React.FC<Props> = (props) => {

    return (

        <>
        
            <title>Stack Attack</title>
            <p>Stack Attack is an on-chain word game. The goal of the game is to answer as many questions as possible. The in-game token SKK is earned as questions are answered. Questions are organized in stacks and players are able to add their own questions on top of a stack of existing questions.</p>
        
        </>
    )
}