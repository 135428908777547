import React from "react";

interface Props {

}

export const DocTokenomics: React.FC<Props> = (props) => {

    return (

        <>
        
            <title>Tokenomics</title>

            <h4>Game Tokenomics</h4>
            <br/>
            <p>The utility token for Stack Attack is SKK. All rewards are paid in SKK and SKK can be used to attack the stack by increasing or decreasing the weight of a question.</p>
            <br/>
            <h5>Token Supply</h5>
            {}
            <p>No SKK tokens were minted before the release of the game. The total SKK supply has a direct linear relationship to the transactions made by players while playing the game. If we manage to meet our milestones we will consider an airdrop to early adopters based on their qNFT ownership.</p>
            <br/>
            <h5>qNFT</h5>
            <p>Question NFTs (qNFT) are the SKK earners of the game. SKK tokens are earned for each qNFT owned. Each stack of questions consists of a base qNFT at the bottom (earning the bulk of stack rewards) and up to 9 qNFT's on top each earning a smaller amount of rewards.</p>
            <br/>
            <h5>cNFT</h5>
            <p>Coupon NFTs (cNFT) are redeemable for qNFT at a 1 to 1 ratio. cNFT facilitate the onboarding of new players and can be minted with FTM or SKK. At times cNFT of various generations will be available for minting and redeemable for qNFT with different characteristics. For example: boost class cNFT typically have a generation that convert into a qNFT that produce bonus mint rewards. On occasion, very valuable BASE cNFT's will be claimable as rewards from existing qNFT's. BASE cNFT's are valuable because they are redeemable for base qNFT's (allows the owner to create the base question of a new stack). When a qNFT is "burned" it can be reclaimed as a coupon cNFT.
            </p>
            <br/>
            {}
            <h5>Inflationary Pressure</h5>
            <p>Points Rewards and Stackflation Rewards are inflationary. Only players who previously minted cNFT and / or qNFT can claim point rewards. Rewards are calculated as the linear SKK token equivalant of the total player transactions in any given epoch.</p>
            <br/>
            <h5>Neutral Pressure</h5>
            <p>Mint Rewards are neutral as they are minted and distributed to existing qNFT in the stack. The topmost qNFT does not receive mint rewards and their share is awarded to the protocol wallet instead. Additionally the mint fees in FTM are awarded to the protocol wallet with the goal of supporting the project and providing liquidity.</p>
            <br/>
            <h5>Deflationary Pressure</h5>
            <p>Attacking the stack by spending SKK tokens to increase / decrease question weight is deflationary as SKK tokens are deposited to the protocol wallet and used for supporting the project and providing liquidity.</p>
        
        </>
    )
}







