import React from "react";

interface Props {

}

export const DocGameMechanics: React.FC<Props> = (props) => {

    return (

        <>
        
            <title>Game</title>

            {}
            <h4>Game Mechanics</h4>
            <br/>
            <p>The goal of the game is to answer as many questions as possible. Earn points for each answer which can be claimed as SKK tokens. 1 point = 1 SKK. The amount of points claimable are rate limited to 25 points / 24 hour period.</p>
            <br/>
            <h6>Step 1/7: Select a stack of questions</h6>
            <p>Each green box represent a stack of questions. Use the "Select Stack” button to select a stack and view it's questions.</p>
            <br/>
            <h6>Step 2/7: Mint a Coupon</h6>
            <p>Start by minting a coupon (cNFT). With a cNFT in your wallet you are connected as a player. The initial cost of cNFT is 5 FTM plus the gas cost associated with the mint transaction. A cNFT coupon is redeemable for a qNFT question at any time.</p>
            <br/>
            <h6>Step 3/7: Answer Questions</h6>
            <p>Starting with the base question, answer as many questions as your points allowance allows. Points are awarded for each answer. 1 point for a preferred answer and 2 points for a non-preferred answer. Please note that both questions and answers are on-chain and visible to all.</p>
            <br/>
            <h6>Step 4/7: Claim points</h6>
            <p>Use the “claim” button in the appbar to claim points. Each point = 1 SKK. Point claims are limited to 25 SKK per 24 hour period. Once you reach the claim limit you can recharge your allowance using the account balance button.</p>
            <br/>
            <h5>Minting new questions</h5>
            <p>Questions (qNFT) are stacked on top of each other in groups of 10. Each stack has a base question at the bottom and new questions can be minted on top of the base question. Answer all existing questions in a stack (or a minimum of 4) before minting a new question. Currently the cost to mint a new question is 5 FTM. Whenever a new question is minted on top of the stack an equivalent (as determined by the emission algorithm) amount of SKK tokens are minted and distributed to each question based on their stack position. Mint rewards are claimable in the claims tab of each owned question. Questions can be added until a stack reaches a height of 10. After a stack reach full height the topmost question will be burned once per epoch. When a qNFT is burned they are converted back to cNFT and can be used to mint a new qNFT.</p>
            <br/>
            <h6>Step 5/7: Add your own question</h6>
            <p>Use the “Add” button in the appbar to mint a qNFT question on top of the current stack. Mint directly with FTM or choose to convert the cNFT coupon minted in step 2. Please note that you have to answer all existing questions (or minimum of 4) before adding a new question.</p>
            <br/>
            <h6>Step 6/7: Attack the Stack</h6>
            <p>Use the “attack” button to increase or decrease the attack weight of a qNFT and potentially change the stackposition of that question. A lower stack position will receive a higher percentage of game rewards and a higher stack position risks being burned.</p>
            <br/>
            <h5>Stackposition</h5>
            <p>The base question is at stackposition 0 and cannot be attacked. The base question receives the highest share of emissions and mint rewards while a higher stackposition receive a smaller share. The share of each qNFT is expressed as their stackfactor (max 10000). The stackpostion of all non-base questions are subject to recalculation once per epoch.</p>
            <br/>
            <h5>Attack</h5>
            <p>Attack the Stack by spending SKK tokens to adjust the attack weight of a qNFT. Attack weight can be both increased or decreased during a round of gameplay (epoch) with the value decaying at a rate of 1 per second. At the end of an epoch each stack can be readjusted by the first player to click on the “Recalculate Stack” button. The first player to click the Recalc button will be rewarded with a recalc fee of 5 SKK.</p>
            <br/>
            <h5>Recalculating Stackposition</h5>
            <p>Starting with the highest stackposition the attack weight of the top question is compared with its first neighbor below (lower stackposition) If the attacker has a higher attack weight it will switch it's stackposition with the loser and continue the “attack” to the next question below and the attack continues. If the attacker has a lower attack weight the recalculation stops and all questions below remain in their current stackpostion.</p>
            <br/>
            <h6>Step 7/7: Claim rewards</h6>
            <p>Use the “claims” tab (on each question) to claim mint rewards and stackflation rewards of your qNFT's.</p>
            <br/>
            <h5>Stackflation</h5>
            <p>During each epoch (round of gameplay) the amount of activity in the system is quantified and minted as stackflation emissions with rewards distributed to questions in the next epoch according to their stackfactor (stackposition).</p>
            <br/>
            
        
        </>
    )
}







