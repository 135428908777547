import React from "react";




import homeback from '../assets/homeback.png'

interface Props {

}

export const Home: React.FC<Props> = (props) => {

    return (

        <>
        
            <title>Stack Attack Home Page</title>

            <div className="xad-bigsec">
                
                {}
                {}
                <img className="ad-homeimg" src={homeback}></img>

                {}
                <br/>
            
                {}
                <div className="ad-hometalk m-5 text-center">
                    <h3>Stack Attack is an Ask-to-Earn NFT game.</h3>
                </div>
                <div className="ad-hometalk m-5 text-center">
                    <h3>Answer questions to earn points.</h3>
                </div>
                <div className="ad-hometalk m-5 text-center">
                    <h3>Ask questions to earn SKK.</h3>
                </div>
                <div className="ad-hometalk m-5 text-center">
                    <h3>Ask-to-Earn.</h3>
                </div>
            </div>
        </>
    )
}