import React, { useContext, useEffect, useState, useRef } from "react";

import { ethers } from 'ethers';

import { TokenCampaignStructOutput } from "../hardhat/typechain/contracts/facets/CouponFacet";
import { EpochDetailStructOutput } from "../hardhat/typechain/contracts/facets/StackflationFacet";

import { Alert, Button, Col, Row, Toast, ToastContainer } from "react-bootstrap";
import { truncateEthAddress } from "./CSCommon";



declare var window: any

let initran = false;

interface BuyItem {
    campid: ethers.BigNumber;
    uri: string;
    title: string;
    desc: string;
    availcnt: number;
    bonus: number;
    upcoming: boolean;
    soldout: boolean;
    expired: boolean;
    skkprice: ethers.BigNumber;
    ethprice: ethers.BigNumber;
    iscampaign: boolean;
}

interface Props {
    currentaddress: string;
    toasterr: string;
    setToasterr: (value: React.SetStateAction<string>) => void;
    loginhelp: {
        connectclicked: boolean;
        additionalmsg: string;
        showacconclick: boolean;
    }
    tryConnect: any;
    tcCampaigns: TokenCampaignStructOutput[];
    epDetail?: EpochDetailStructOutput;
    
    handleBut: any;
}

export const BuyNft: React.FC<Props> = (props) => {

    

    const getBonus = (uri: string) => {

        if(uri.substring(0,3) == "b10") {
            return 10
        } else if(uri.substring(0,3) == "b20") {
            return 20
        } else if(uri.substring(0,3) == "b30") {
            return 30
        } else if(uri.substring(0,3) == "b40") {
            return 40
        } else if(uri.substring(0,3) == "b50") {
            return 50
        } else if(uri.substring(0,3) == "b60") {
            return 60
        } else {
            return 0
        }
    }

    const filterCampaign = (cp: TokenCampaignStructOutput[]) => {
        let bi: BuyItem[] = []
        
        if(props.epDetail) {

            const fc = cp.filter((k)=>{
                if(k._active
                    
                    
                    
                ) {
                    return true;
                }
            })
            
            
            fc.forEach((i) => {
                bi.push({
                    campid: i._campid,
                    uri: i._uri,
                    title: "Mint with SKK ",
                    availcnt: i._availcnt.toNumber(),
                    bonus: getBonus(i._uri),
                    desc: "Similar to regular class cNFT's but mintable with SKK instead of FTM. When indicated this cNFT convert to a qNFT that earn a bonus reward. Limited supplies. Sale ends soon.",
                    ethprice: ethers.BigNumber.from(0),
                    skkprice: i._skkprice,
                    soldout: i._availcnt.sub(i._soldcnt).lte(0),
                    expired: props.epDetail ? props.epDetail.epochidx.gte(i._startep.add(i._runforep)) : false,
                    upcoming: props.epDetail ? props.epDetail.epochidx.lt(i._startep) : false,
                    iscampaign: true
                })
            })

            

            
            
            
            
            
            
            
            
            
            
            
            
            

        } 

        return bi
    }

    return (

        <>

            <title>Buy cNFT</title>

            {}
            <div className="xad-bigsec">

                {}

                <div className="ad-hometalk m-5 text-center">
                    {}

                    <h3>Stack Attack is an Ask-to-Earn NFT game on the Fantom blockchain.</h3>
                    <br />

                    {}

                    <h3>Tired of breeding crabs, cars and cabbage?</h3>
                    <br />

                    <h3>Try something different... mint a Question.</h3>
                    <br />


                    <div className="ad-connectdata xmx-8 my-3 px-8 xg-0">

                        <Row className="px-2 py-1">
                            { props.currentaddress != "" &&
                                <Col className="text-start h5">connected as: {truncateEthAddress(props.currentaddress)}</Col>
                            }

                        </Row>

                        <Row className="p-4">
                            { props.currentaddress != "" &&
                                <Col className="text-start h5">Start by minting a cNFT with one of the following methods</Col>
                            }

                            { props.currentaddress == "" && props.loginhelp.additionalmsg != "" && props.loginhelp.connectclicked == false &&
                                <Col>
                                    <Button onClick={props.tryConnect} >Connect your wallet to mint</Button>
                                </Col>
                            }
                            { props.currentaddress == "" && props.loginhelp.additionalmsg != "" && props.loginhelp.connectclicked == true &&
                                <Col>
                                    <Alert >{props.loginhelp.additionalmsg}</Alert>
                                </Col>
                            }

                        </Row>

                        { props.currentaddress != "" &&
                            <Row className="container-lg justify-content-around p-2 mx-0 xpx-0 xd-flex">

                                <Col className="col-11 col-md-5 ad-campaign d-flex flex-column mb-2 xg-0">
                                    <Row className="ad-campaign-top xmb-2 p-1 justify-content-center">Mint with FTM</Row>
                                    <Row className="m-1 p-2 justify-content-center">Mint a regular cNFT to get started. Owning a cNFT enables you to answer questions and to earn SKK tokens. Later apply the cNFT to mint a question qNFT thereby increasing your SKK earnings.</Row>
                                    
                                    <Row className="m-1 mt-auto">
                                        <Col>class</Col>
                                        <Col>regular</Col>
                                    </Row>
                                    <Row className="m-1 xmt-auto">
                                        <Col>generation</Col>
                                        <Col>fynbos</Col>
                                    </Row>
                                    <Row className="m-1 xmt-auto">
                                        <Col>bonus</Col>
                                        <Col>n/a</Col>
                                    </Row>
                                    
                                    <Row className="m-1 xmt-auto">
                                        <Col>supply</Col>
                                        <Col>unlimited</Col>
                                    </Row>
                                    <Row className="m-1">
                                        <Col>current price</Col>
                                        <Col>{props.epDetail ? ethers.utils.formatEther(props.epDetail.nftprice) : "na"} FTM</Col>
                                    </Row>
                                    <Row className="ad-campaign-bot  p-2">
                                        <Col>
                                            <Button variant="primary" size="sm" 
                                                    onClick={(e) => props.handleBut("initftm",e)} >
                                                        Mint for {props.epDetail ? ethers.utils.formatEther(props.epDetail.nftprice) : "na"} FTM
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>

                                { filterCampaign(props.tcCampaigns).map((i: BuyItem, idx) => {

                                    return <Col key={idx} className="col-11 col-md-5 ad-campaign d-flex flex-column mb-2 mx-0">
                                        <Row className="ad-campaign-top xm-1 p-1 justify-content-center">{i.title}</Row>
                                        <Row className="m-1 p-2 justify-content-center">{i.desc}</Row>
                                        <Row className="m-1 mt-auto">
                                            <Col>class</Col>
                                            <Col>boost</Col>
                                        </Row>
                                        <Row className="m-1 xmt-auto">
                                            <Col>generation</Col>
                                            <Col>{i.uri}</Col>
                                        </Row>
                                        <Row className="m-1 xmt-auto">
                                            <Col>bonus</Col>
                                            <Col>{i.bonus} %</Col>
                                        </Row>
                                        <Row className="m-1 xmt-auto">
                                            <Col>supply</Col>
                                            <Col>{i.upcoming ? "soon" :  ((i.expired || i.soldout) ? "sold out" : (i.iscampaign ? "limited" : "unlimited"))}</Col>
                                        </Row>
                                        
                                        {}
                                        <Row className="m-1">
                                            <Col>sale price</Col>
                                            <Col>{ethers.utils.formatEther(i.skkprice)} SKK</Col>
                                        </Row>
                                        <Row className="ad-campaign-bot xmt-2 p-2">
                                            <Col>
                                                <Button variant="primary" size="sm" 
                                                    onClick={(e) => props.handleBut("initskk",e, i.campid)} >
                                                        Mint for {ethers.utils.formatEther(i.skkprice)} SKK
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>

                                        


                                })}



                                {}

                                        {}

                            </Row>
                        }

                    </div>


                    {props.toasterr != "" &&
                        <ToastContainer position="top-end">
                        <Toast bg="warning"  onClose={() => props.setToasterr("")} show={props.toasterr!=""} delay={3000} autohide>
                        <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                            <strong className="me-auto">ERROR</strong>
                            <small>now</small>
                        </Toast.Header>
                        <Toast.Body >{props.toasterr}</Toast.Body>
                        </Toast>
                        </ToastContainer>
                    }

                </div>
            </div>

        </>
    )
}