import React from "react";

interface Props {

}

export const DocStrategy: React.FC<Props> = (props) => {

    return (

        <>
        
            <title>Strategy</title>

            <h4>Game Strategy</h4>
            <br/>
            <h5>Answering Questions</h5>
            <p>Earn SKK by answering questions. The maximum points claimable is 25 / day. Recharge your points allowance by spending SKK.</p>
            <br/>

            <h5>Minting your own Questions</h5>
            <p>Mint a question on top of a stack. Claim mint rewards and stackflation rewards as other questions are added to the stack.</p>
            <br/>

            <h5>Boost your own questions</h5>
            <p>Increase the adjusted weight of your questions to increase the probability of a lower stackpostion during the next round / epoch. Lower stackpostions earn a bigger share of mint rewards and stackflation rewards.</p>
            <br/>

            <h5>Attack other player's questions</h5>
            <p>Decrease the adjusted weight of other player's questions to increase the probability of defending against or winning out during an attack.</p>
            <br/>

            <h5>Help by recalculating a stack</h5>
            <p>The order of questions in each stack can be recalculated once per epoch. Any player can trigger the recalculation and earn the recalc fee of 5 SKK. The recalc fee is an incentive for incurring gas fees with the recalc transaction.</p>
            <br/>

            <h5>Clean the stack</h5>
            <p>Low quality questions can reduce the popularity of a stack. By decreasing the adjusted weight of a question it will eventually be burned as it reaches the top of the stack.</p>
        
        </>
    )
}