import React from "react";

interface Props {

}

export const DocIndex: React.FC<Props> = (props) => {

    return (

        <>
        
            <title>Doc Index</title>
            <h4>Stack Attack Quickstart</h4>
            <br/>
            <p>Stack Attack is an ask-to-earn NFT game. The goal of the game is to answer as many questions as possible. Each question is defined with a NFT. SKK tokens are earned as questions are answered. Questions are organized in stacks and players are able to add their own questions. Players earn additional SKK tokens for each NFT owned and earnings differ depending on the position of a question in the stack. Players can change the position of their questions by attacking the stack. To get started a player have to mint a coupon (cNFT) which can be redeemed for a question (qNFT) while playing the game.</p>

            <p>More information available from the topics in the sidebar.</p>
        
        </>
    )
}