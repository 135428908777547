import React from "react";


import nameicon from '../assets/stackattacklogolong.png'

interface Props {

}

export const Footer: React.FC<Props> = (props) => {

    return (

        <>
            {}
            <div className="mt-auto">
                <div className="xd-flex px-3">
                    <hr />
                </div>
                <div className="d-flex justify-content-evenly">
                    <div className="xcol-3">
                        <div className="m-2">
                            <img className="ad-homelogo2" src={nameicon}></img>
                        </div>
                        <div className="m-2 text-center">
                            <h6>Ask-to-Earn NFT Game</h6>
                        </div>
                    </div>
                    {}
                    <div className="xcol-3">
                        <div className="ad-social-link m-2">
                            {}
                            {}
                            <h6><a href={process.env.REACT_APP_TELEGRAM}>Telegram</a></h6>
                            
                        </div>
                        
                        <div className="ad-social-link m-2">
                            {}
                            <h6><a href={process.env.REACT_APP_TWITTER}>Twitter</a></h6>
                        </div>
                    </div>
                </div>
                <div className="xd-flex px-3">
                    <hr />
                </div>
                
                <div className="d-flex justify-content-center pb-3">
                    <span>Copyright © 2023 Stack Attack. All rights reserved.</span>
                </div>
                
            </div>
        
        </>
    )
}