import React, { useContext, useEffect, useState, useRef } from "react";


import { Route, Routes, Link } from "react-router-dom"

import './App.css';





import 'bootstrap/dist/css/bootstrap.min.css';




import { RegNavMainTop } from './components/RegNavMainTop';
import { RegNavSide } from "./components/RegNavSide";


import { Home } from './components/Home'
import { BuyNft } from './components/BuyNft';
import { Launch } from './components/Launch';


import { DocStackAttack } from "./components/DocStackAttack";
import { DocStrategy } from "./components/DocStrategy";
import { DocIndex } from "./components/DocIndex";
import { DocGameMechanics } from "./components/DocGameMechanics";
import { DocRewards } from "./components/DocRewards";
import { DocTokenomics } from "./components/DocTokenomics";
import { DocToDo } from "./components/DocToDo";
import { Footer } from "./components/Footer";
import { DocTermsOfService } from "./components/DocTermsOfService";
import { Airdrop } from "./components/Airdrop";
import { extractError } from "./components/CSCommon";

import { modalstate, useracc } from './components/CSInterface'



import { ethers } from 'ethers';



















import diamondAbi from './hardhat/deployments/fantommain/Diamond.json';
import couponFacetAbi from './hardhat/deployments/fantommain/CouponFacet.json';
import readFacetAbi from './hardhat/deployments/fantommain/ReadFacet.json';
import cNftAbi from './hardhat/deployments/fantommain/Cnft.json';
import cSKKAbi from './hardhat/deployments/fantommain/SKKtoken.json';
import stackflationFacetAbi from './hardhat/deployments/fantommain/StackflationFacet.json';

import { TokenCampaignStructOutput } from "./hardhat/typechain/contracts/facets/CouponFacet";
import { EpochDetailStructOutput } from "./hardhat/typechain/contracts/facets/StackflationFacet";
import { Diamond, CouponFacet, StackflationFacet, SKKtoken, ReadFacet } from "./hardhat/typechain";
import { Cnft } from './hardhat/typechain/contracts/Cnft';
import { BuyModal } from "./components/BuyModal";


declare var window: any

let initran = false;


    


function App() {


    const fdiamond = useRef<Diamond>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, diamondAbi.abi) as Diamond);
    const fcoup = useRef<CouponFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, couponFacetAbi.abi) as CouponFacet);
    const fread = useRef<ReadFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, readFacetAbi.abi) as ReadFacet);
    const fstackflation = useRef<StackflationFacet>(new ethers.Contract(process.env.REACT_APP_ADR_DIAMOND || diamondAbi.address, stackflationFacetAbi.abi) as StackflationFacet);
    const cnft = useRef<Cnft>(new ethers.Contract(process.env.REACT_APP_ADR_DFT || cNftAbi.address, cNftAbi.abi) as Cnft);
    const skk = useRef<SKKtoken>(new ethers.Contract(process.env.REACT_APP_ADR_DFT || cSKKAbi.address, cSKKAbi.abi) as SKKtoken);


    const ethprovider = useRef<ethers.providers.Web3Provider>();
    const hhprovider = useRef<ethers.providers.JsonRpcProvider>();

    const [currentaddress, setCurrentaddress] = useState("")

    const [toasterr, setToasterr] = useState("")

    const [adminInp, _setAdminInp] = useState({
        tval: "4", minemodeauto: true, autoepoch: false
        , lastloginprovider: "na"
        , adminavail: process.env.REACT_APP_ADMIN == "1"
        , bboxes: process.env.REACT_APP_BBOXES == "1"
        , dispdebugAacc: false
        , startwithJRPCprovider: process.env.REACT_APP_START_JRPC == "1"
        , testAccStartIdx: 0
        , defaultTestId: process.env.REACT_APP_DEFAULT_TESTID_JRPC ? parseInt(process.env.REACT_APP_DEFAULT_TESTID_JRPC) : 0
    });
    const adminInpRef = useRef(adminInp)
    const setAdminInp = (la: any) => { adminInpRef.current = la; _setAdminInp(la) }

    const [tcCampaigns, setTcCampaings] = useState<TokenCampaignStructOutput[]>([])

    const [epDetail, setEpDetail] = useState<EpochDetailStructOutput>()

    const [uacc, setUacc] = useState<useracc>()

    const [skksupply, setSkksupply] = useState<ethers.BigNumber>()

    const [loginhelp, setLoginhelp] = useState({ connectclicked: false, additionalmsg: "", showacconclick: false })


    const [mymodalstate, _setMymodalstate] = useState<modalstate>({tranapproved: false, 
                    traninitiated: false, tranminted: false, showme: false, buywhat:"",errormsg:"", infomsg:""})
    
    const mymodalstateRef = useRef(mymodalstate)
    const setMymodalstate = (ur: any) => { mymodalstateRef.current = ur; _setMymodalstate(ur); }
    
    



    const refreshAccForAdr = async (adr: string) => {

        

        const usersum = await fread.current.getUserSummary(adr)

        

        setUacc(usersum)

    }



    
    
    
    
    
    const ethLogin = async (initialep?: ethers.providers.Web3Provider, promptaccounts?: boolean) => {

        
        
        
        let ep = initialep || ethprovider.current;

        if (!ep) {
            
            
            setLoginhelp({ ...loginhelp, additionalmsg: "please install MetaMask A" })

            return
        }

        
        const echain = await ep.send("eth_chainId", [])
        

        
        const hexchaincomp = process.env.REACT_APP_CHAIN_ID ? ethers.utils.hexValue(parseInt(process.env.REACT_APP_CHAIN_ID)) : ethers.utils.hexValue(1337)

        
        
        
        

        
        if (echain != hexchaincomp) {
            
            
            setLoginhelp({ ...loginhelp, additionalmsg: "wrong network. please connect to fantom." })
            return
        }

        let ainp = Object.assign({}, adminInp);
        ainp.lastloginprovider = "eth"
        setAdminInp(ainp);

        
        let mmrequest = "eth_accounts"
        if (promptaccounts) {
            mmrequest = "eth_requestAccounts"
        }
        
        
        

        
        
        const eacc = await ep.send(mmrequest, [])
            .catch((err) => {

                

                if (err.code === 4001) {
                    
                    
                    
                    
                    setLoginhelp({ ...loginhelp, additionalmsg: "please connect to MetaMask AA" })
                } else {
                    console.error(err);
                }

            })



        handleAccountsChanged(eacc);



    } 


    const handleAccountsChanged = async (eacc: any) => {

        

        let ep = ethprovider.current;

        if (ep == undefined) {
            
            return;
        }

        let ainp = Object.assign({}, adminInp);
        ainp.lastloginprovider = "eth"
        setAdminInp(ainp);

        if (eacc) {

            

            if (eacc.length == 0) {

                
                
                
                setLoginhelp({ ...loginhelp, additionalmsg: "please connect to metamask", showacconclick: true })
                return

            } else if (eacc[0] != currentaddress) {
                




            } else {
                

            }

        } else {
            
            return
        }

        
        

        
        

        
        
        setLoginhelp({ ...loginhelp, additionalmsg: "" })


        
        const curadr = eacc[0]

        
        

        setCurrentaddress(curadr);

        
        
        
        
        

        


        
        const tsigner = ep.getSigner(curadr)
        

        const signercuradr = await tsigner.getAddress();

        
        

        fdiamond.current = (fdiamond.current.connect(tsigner));
        skk.current = (skk.current.connect(tsigner));
        cnft.current = (cnft.current.connect(tsigner));
        fcoup.current = (fcoup.current.connect(tsigner));
        fread.current = (fread.current.connect(tsigner));
        fstackflation.current = (fstackflation.current.connect(tsigner));


        skk.current.on("Transfer",(from: string,to: string,val: ethers.BigNumber) => {
            
            
            
            
            if(ethers.utils.getAddress(from) == ethers.utils.getAddress(curadr) 
                || ethers.utils.getAddress(to) == ethers.utils.getAddress(curadr)) {
                
                refreshAccForAdr(curadr)
            }      
        })


        cnft.current.on("Transfer",(from: string,to: string,tokenid: ethers.BigNumber) => {
            
            
            
            
            if(ethers.utils.getAddress(from) == ethers.utils.getAddress(curadr) 
                || ethers.utils.getAddress(to) == ethers.utils.getAddress(curadr)) {
                
                refreshAccForAdr(curadr)
            } 
        })

        refreshAccForAdr(curadr)


        loadCampaign()

    }


    
    const jsonRPClogin = async (
        sidx: number
    ) => {

        

        
        let ainp = Object.assign({}, adminInp);
        ainp.dispdebugAacc = false;
        ainp.lastloginprovider = "jrpc"
        setAdminInp(ainp);

        
        
        
        
        const jrpc = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JRPC_URL);

        
        hhprovider.current = jrpc

        
        
        
        
        
        
        
        
        

        
        
        

        
        
        
        
        
        

        
        

        fdiamond.current = (fdiamond.current.connect(jrpc.getSigner(sidx)));
        skk.current = (skk.current.connect(jrpc.getSigner(sidx)));
        cnft.current = (cnft.current.connect(jrpc.getSigner(sidx)));
        fcoup.current = (fcoup.current.connect(jrpc.getSigner(sidx)));
        fread.current = (fread.current.connect(jrpc.getSigner(sidx)));
        fstackflation.current = (fstackflation.current.connect(jrpc.getSigner(sidx)));

        const newadr = await jrpc.getSigner(sidx).getAddress();
        

        
        skk.current.on("Transfer",(from: string,to: string,val: ethers.BigNumber) => {
            
            
            
            
            if(ethers.utils.getAddress(from) == ethers.utils.getAddress(newadr) 
                || ethers.utils.getAddress(to) == ethers.utils.getAddress(newadr)) {
                
                refreshAccForAdr(newadr)
            } 
        })
        cnft.current.on("Transfer",(from: string,to: string,tokenid: ethers.BigNumber) => {
            
            
            
            
            if(ethers.utils.getAddress(from) == ethers.utils.getAddress(newadr) 
                || ethers.utils.getAddress(to) == ethers.utils.getAddress(newadr)) {
                
                refreshAccForAdr(newadr)
            } 
        })

        refreshAccForAdr(newadr)

        setCurrentaddress(newadr)

        loadCampaign()

    };

   


    useEffect(() => {

        

        

        if (!window.ethereum) {
            
            
            setLoginhelp({ ...loginhelp, additionalmsg: "please install MetaMask B" })

        } else {
            

            const ep = new ethers.providers.Web3Provider(window.ethereum);

            
            
            ethprovider.current = ep

            if (ep && !initran) {

                initran = true


                window.ethereum.on('accountsChanged', handleAccountsChanged);

                window.ethereum.on('chainChanged', () => {
                    
                    window.location.reload();
                });

                
                
                
                
                if (adminInpRef.current.startwithJRPCprovider) {
                    

                    jsonRPClogin(adminInpRef.current.defaultTestId);

                } else {

                    ethLogin(ep)

                }



            }

        }

        

        return () => {
            
            

        };

    }, []);




    const tryConnect = async () => {

        if (loginhelp.showacconclick) {

            if (adminInp.startwithJRPCprovider) {
                
                await jsonRPClogin(adminInp.defaultTestId)
            } else {
                
                ethLogin(undefined, true)
            }

            setLoginhelp({ ...loginhelp, connectclicked: true, showacconclick: false })

        } else {

            
            setLoginhelp({ ...loginhelp, connectclicked: true })
        }
    }


    const loadCampaign = async () => {

        


        await fcoup.current.GetCampaigns().then((res) => {
            

            setTcCampaings(res)

        }).catch((err) => {

            

        }).finally(() => {

            

            fstackflation.current.getEpoch().then((res2) => {

                

                setEpDetail(res2)
            })

            skk.current.totalSupply().then((res3) => {
                setSkksupply(res3)
            })

        })

        

    }

    const handleModal = async ( 
        cmd: string,
        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        cid?: ethers.BigNumber
    ) => {

        if(e) e.preventDefault();

        

        

        try {
            if (cmd == 'defaults') {


            } else if (cmd == "initftm") {
                
                setMymodalstate({...mymodalstateRef.current,showme:true,buywhat: "ftm", errormsg:"", infomsg:""
                                ,traninitiated: false, tranapproved: false, tranminted: false, campid: cid})

            } else if (cmd == "initskk") {
                
                setMymodalstate({...mymodalstateRef.current,showme:true,buywhat: "skk", errormsg:"", infomsg:""
                                ,traninitiated: false, tranapproved: false, tranminted: false, campid: cid})

            } else if (cmd == "hidemodal") {
                
                setMymodalstate({...mymodalstateRef.current,showme:false})

            } else if (cmd == 'buyftmorskk') {

                try {

                    let ms = {...mymodalstateRef.current, errormsg:"", infomsg:"" ,traninitiated: true, tranapproved: false, tranminted: false}
                    setMymodalstate(ms)

                    
                    if(mymodalstateRef.current.buywhat == "ftm") {
                        
                        setMymodalstate({...mymodalstateRef.current, tranapproved: true, tranminted: false})

                        const buyRet = await fcoup.current.BuyRegularCoupon(currentaddress, { value: epDetail?.nftprice })

                        await buyRet.wait()

                        setMymodalstate({...mymodalstateRef.current, traninitiated: false, tranapproved: true, tranminted: true})

                        await refreshAccForAdr(currentaddress)

                        setMymodalstate({...mymodalstateRef.current, infomsg: "Mint successful"})


                    } else if(mymodalstateRef.current.buywhat == "skk") {
                        
                        if (mymodalstateRef.current.campid != undefined) {

                            const tsworkaroundBN = mymodalstateRef.current.campid

                            const allowbal = await skk.current.allowance(currentaddress, diamondAbi.address)
        
                            
                            const citm = tcCampaigns.find((i) => { if (i._campid.eq(tsworkaroundBN)) return true })
        
                            if (citm) {
        
                                if (allowbal.lt(citm._skkprice)) {
                                    await (await skk.current.approve(diamondAbi.address, citm._skkprice)).wait()
                                }

                                setMymodalstate({...mymodalstateRef.current, tranapproved: true, tranminted: false})
        
                                const buytran = await fcoup.current.CampaignBuyRegularCoupon(mymodalstateRef.current.campid)
        
                                await buytran.wait()

                                setMymodalstate({...mymodalstateRef.current, traninitiated: false, tranapproved: true, tranminted: true})

                                await refreshAccForAdr(currentaddress)

                                setMymodalstate({...mymodalstateRef.current, infomsg: "Mint successful"})

        
                            } else {
                                
                            }
        
        
                        } else {
                            
                        }

                    }


                } catch (err) {

                    
                    
                    
                    

                    

                    
                    

                    
                    let ms = {...mymodalstateRef.current, traninitiated:false, errormsg: extractError(err as string, "Error claiming points") }
                    setMymodalstate(ms)
                }

            }

        } catch (err) {

            setToasterr(extractError(err as string, "Error claiming points"))
        }

    }


    return (
        <>

            <div className="ad-app">

                {}
                <Routes>
                    {}
                    <Route element={< RegNavMainTop />} >
                        <Route path="/" element={< Home />} />
                        <Route path="/buynft" element={< BuyNft currentaddress={currentaddress}
                            toasterr={toasterr}
                            setToasterr={setToasterr}
                            loginhelp={loginhelp}
                            tryConnect={tryConnect}
                            tcCampaigns={tcCampaigns}
                            epDetail={epDetail}
                            handleBut={handleModal} />} />
                        {}
                        <Route path="/docs" element={< RegNavSide />} >
                            <Route index element={< DocIndex />} />
                            <Route path="game" element={< DocGameMechanics />} />
                            <Route path="rewards" element={< DocRewards />} />
                            <Route path="stackattack" element={< DocStackAttack />} />
                            <Route path="strategy" element={< DocStrategy />} />
                            <Route path="tokenomics" element={< DocTokenomics />} />
                            {}
                            <Route path="terms" element={< DocTermsOfService />} />
                        </Route>
                        {}
                    </Route>
                </Routes>

                {}
                {}
                {}


                { uacc &&
                    < BuyModal 
                        errormsg=""
                        modalh={handleModal}
                        mstate={mymodalstateRef.current}
                        uacc={uacc}
                    />
                }

                < Footer />

            </div>

        </>

    );
}

export default App;
