import React from "react"

import { modalstate, useracc } from './CSInterface'

import { Button, Container, Row, Col, ProgressBar, Modal, Form, Spinner, Alert } from 'react-bootstrap'

import { ethers } from "ethers";
import { CSpinnerButton } from "./CSpinnerButton";
import { fToken } from "./CSCommon";


import { MdCheck } from "react-icons/md";


interface Props {
    errormsg: string;
    modalh: any;
    mstate: modalstate;
    uacc: useracc;
}

export const BuyModal: React.FC<Props> = (props) => {


    return (
        <>
            <Modal show={props.mstate.showme} onHide={() => props.modalh("hidemodal")}>
                <Modal.Header closeButton>
                    <Modal.Title>Mint cNFT</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Container>

                        {}

                        <Alert show variant="success">

                            {props.mstate.buywhat == "ftm" &&

                                <p>Your balance is {fToken(props.uacc.ftmbal,2)} FTM and {props.uacc.cnftcnt.toNumber()} cNFT</p>

                            }


                            {props.mstate.buywhat == "skk" &&

                                <p>Your balance is {fToken(props.uacc.skkbal,2)} SKK and {props.uacc.cnftcnt.toNumber()} cNFT</p>

                            }


                            {}

                            {props.mstate.buywhat == "ftm" &&
                                <CSpinnerButton spinstat={props.mstate.traninitiated && props.errormsg == ""}
                                    butstring={"Mint 1 cNFT with FTM"}
                                    clickhandler={(e: any) => props.modalh("buyftmorskk",e)}
                                    butclass="outline-success" 
                                />
                            }
                            {props.mstate.buywhat == "skk" &&
                                <CSpinnerButton spinstat={props.mstate.traninitiated && props.errormsg == ""}
                                    butstring={"Mint 1 cNFT with SKK"}
                                    clickhandler={(e: any) => props.modalh("buyftmorskk",e)}
                                    butclass="outline-success" 
                                />
                            }



                        </Alert>

                        {}

                        { props.mstate.errormsg != "" ?
                            <Alert variant="danger" className="mt-1 mb-0">{props.mstate.errormsg}</Alert>
                            :
                            <div>
                                {props.mstate.traninitiated && !props.mstate.tranapproved && !props.mstate.tranminted &&
                                    <div>
                                        <Spinner animation="border" size="sm"></Spinner><span className="ms-1">approving</span>
                                    </div>
                                }
                                {props.mstate.traninitiated && props.mstate.tranapproved && !props.mstate.tranminted &&
                                    <div><div><MdCheck />approved</div>
                                        <Spinner animation="border" size="sm"></Spinner><span className="ms-1">minting</span>
                                    </div>
                                }

                                {}
                                { props.mstate.tranapproved && props.mstate.tranminted &&
                                    <div>
                                        <div><MdCheck />approved</div>
                                        <div><MdCheck />completed</div>
                                    </div>
                                }
                            </div>
                        }

                        { props.mstate.infomsg != "" &&
                            <Alert variant="success" className="mt-1 mb-0">{props.mstate.infomsg}</Alert>
                        }


                    </Container>
                </Modal.Body>
                <Modal.Footer>

                    <Container>
                        <div className="d-flex justify-content-between">

                            { props.mstate.tranapproved && props.mstate.tranminted ?
                                <div className="x">
                                    <Button variant="secondary" onClick={(e) => props.modalh("hidemodal",e)}>Close</Button>
                                </div>
                            :
                                <div className="x">
                                    <Button variant="secondary" onClick={(e) => props.modalh("hidemodal",e)}>Cancel</Button>
                                </div>
                            }

                        </div>
                    </Container>

                </Modal.Footer>
            </Modal>
        </>
    )

}
