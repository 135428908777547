import React from "react";

import { Button } from 'react-bootstrap'
import { Route, Routes, Link, Outlet, NavLink, useNavigate } from "react-router-dom"



import nameicon from '../assets/stackattacklogolong.png'



interface Props {

}

export const RegNavMainTop: React.FC<Props> = (props) => {

    const navigate = useNavigate()

    return (

        <>
        
            <div className="xd-flex">
                <div className="xcol-3">

                    {}
                    {}

                    {}
                    <nav>
                        <div className="ad-nav ad-topnav nav xd-flex justify-content-evenly mt-1 mb-2">
                            <div><NavLink className="nav-link" to="/"><img className="ad-homelogo2" src={nameicon}></img></NavLink></div>
                            <div className="vr"></div>
                            <div><NavLink className="nav-link" to="/buynft">NFT</NavLink></div>
                            {}
                            <div><NavLink className="nav-link" to="/docs">Quickstart</NavLink></div>
                            {}
                            {}
                            <div><Button onClick={(e)=> window.location.href = process.env.REACT_APP_APPLINK ? process.env.REACT_APP_APPLINK : ""  }>Launch App</Button></div>
                        </div>
                    </nav>

                </div>

                <div className="pt-4 ps-2 pe-2">
                    
                    < Outlet />
                </div>

            </div>

        </>
    )
}