import React from "react";


import {  NavLink, Outlet } from "react-router-dom"

interface Props {

}

export const RegNavSide: React.FC<Props> = (props) => {

    return (

        <>
            <div className="d-flex ad-bigsec">
                <div className="col-3">
                    <nav>
                        <div className="ad-nav nav flex-column mt-2">
                            <div><NavLink className="nav-link" to="game">Game</NavLink></div>
                            <div><NavLink className="nav-link"  to="rewards">Rewards</NavLink></div>
                            {}
                            <div><NavLink className="nav-link"  to="strategy">Strategy</NavLink></div>
                            <div><NavLink className="nav-link"  to="tokenomics">Tokenomics</NavLink></div>
                            {}
                            <div><NavLink className="nav-link"  to="terms">Terms of Service</NavLink></div>
                        </div>
                    </nav>
                </div>

                <div className="pt-4 pe-2">
                    < Outlet />
                </div>
            </div>
        </>
    )
}