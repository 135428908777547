import React from "react";

interface Props {

}

export const DocRewards: React.FC<Props> = (props) => {

    return (

        <>
        
            <title>Rewards</title>

            <h4>Game Rewards</h4>
            <br/>
            <h5>Point Rewards</h5>
            <p>Earn 1 SKK per point. Answer a question using the preferred answer = 2 points. Answer a question with a non-preferred answer = 1 point. Rewards are claimed in the status bar using the claim button. Own at least 1 cNFT or qNFT to be able to claim point rewards.</p>
            <br/>
            <h5>Mint Rewards</h5>
            <p>SKK mint rewards are awarded to a stack when a new question is minted on top. Claim mint rewards on the “claims” tab of the question. Convert at least 1 cNFT to a qNFT to be able to claim mint rewards.</p>
            <br/>
            <h5>Bonus Rewards</h5>
            <p>An additional bonus on top of Mint Rewards. Mint a boost class cNFT and convert it to a qNFT to earn bonus rewards.</p>
            <br/>
            <h5>Stackflation</h5>
            <p>An epoch represents a round of gameplay and lasts for approximately 24 hours. During each epoch the amount of activity in the system is quantified and minted as stackflation rewards. Stackflation rewards are distributed to qNFT's in the following epoch. Claim stackflation rewards on the “claims” tab of the your qNFT question.</p>
            <br/>
            <h5>cNFT Coupons</h5>
            <p>From time to time when activated, coupon rewards will be awarded to the qNFT at stackposition 1 (first question above the base question). Coupon rewards are regular cNFT's or on occasion BASE cNFT's. Base cNFT's are very valuable as they can be used to create a new stack and will always receive the largest percentage of rewards.</p>
            <br/>
            {}
        
        </>
    )
}







