
import { BigNumber, ethers } from 'ethers';

interface BusyByN {
  nftid: string;
  busy: boolean;
}

  
  
  
  
  
  
  
  
  
  
  
  
  
  

  
  
  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  
  export function truncateEthAddress(address: string) {
    const match = address.match(truncateRegex);
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
  }



function truncate(str: string, maxDecimalDigits: number) {
  if (str.includes('.')) {
      const parts = str.split('.');
      return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits);
  }
  return str;
}

export function fToken (v?:ethers.BigNumber,d?:number)  {
  
  if(v) {
    
    const accufactor = 100000

    if(d == undefined) {
      
      

      return truncate(ethers.utils.formatUnits(v,'ether'),4)

    } else {
      
      
      

      return truncate(ethers.utils.formatUnits(v,'ether'),d)
      
    }
  } else {
    return "0";
  }
}


export function fFactor(v?: ethers.BigNumber, d?: number) {
  
  

  if(v == undefined) {
    return ""

  } else {

    if (d == undefined) {
      return v.toString();
    } else {
      return v.toString().substring(0, d);

    }

  }
}

export function xFactor (v?:ethers.BigNumber,d?:number)  {
  if(v != undefined) {
      return v.toString();
  } else {
    return 0;
  }
}

export function checkOrSetBusyByN (pAr: BusyByN [], pNftId: string, pYeOrNe?: boolean)  {
    
  const res = pAr.findIndex((i)=>{
    if(i.nftid == pNftId) return true
  })
  if(res > -1) {
    if(pYeOrNe !== undefined) {
      pAr[res].busy = pYeOrNe
      return true
    } else {
      return pAr[res].busy
    }
    
  } else {
    if(pYeOrNe !== undefined) {
      pAr.push({nftid: pNftId, busy: pYeOrNe})
      return true
    } else {
      pAr.push({nftid: pNftId, busy: false})
      return false
    }
    
  }

}

export function extractError (err: any, nomatch: string) {
    
  const re1 = /\\"message\\":\\"(.*?)\\"/g
  const re2 = /"message":.?"(.*?)"/g
    
    

  
  
  
  
    
    
  
  

  let extract = ""

  
  
  if(typeof err === "string") {

  
    let match1 = re1.exec(err);
    let match2 = re2.exec(err);
    

    if (match1 != undefined && match1.length > 1) {
      
      
      extract = match1[1]
    } else {
      if (match2 != undefined && match2.length > 1) {
        
        
        extract = match2[1]
      } else {
        
        
        extract = nomatch
      }
      
    }

  
  } else {
    
  
  
  
  
  
  
  

    
    if(err && err.code == -32603) {
      

      if(err.data && err.data.message) {
        
        extract = err.data.message
      
      
      } else if(err.message){
        
        
        let match2 = re2.exec(err.message);
        if (match2 != undefined && match2.length > 1) {
          

          
          
          if(match2[1].includes("JSON-RPC")) {
            
            extract = nomatch
          } else {
            
            extract = match2[1]
          }
          
        } else {
          
          
          extract = nomatch
        }
      } else {
        
        extract = nomatch
      }

    } else if(err && err.reason && err.reason != "") {
      
      
      extract = "Error: " + err.reason
    
    
    
    } else {
      

      

      
      
      
      
      
      
      

      
      
        
      
      
      
      

      
      extract = nomatch
      
    }
    

  }

  
  
  return extract
  

}
